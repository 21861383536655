export const personalData = [
  {
    title: "Edad:",
    info: "24 años.",
  },
  {
    title: "Educacion:",
    info: "Instituto Tecnologico Superior de Uruapan.",
  },
  {
    title: "Carrera:",
    info: "Ingenieria en Sistemas Computacionales.",
  },
  {
    title: "Ubicacion:",
    info: "Michoacan, Mexico.",
  },
]
